export const Config = {
    api: {
        endPoint: "https://myplatformapi.pinnacletech.com/api/"
    },
    auth0: {
        domain: "signin.pinnacleauth.com",
        clientId: "rdblx8KYXRNSsyaR1YAe3KAqX8Hnwck2",
        authorizationParams: {
            redirect_uri: 'https://myplatform.pinnaclereliability.com/callback',
            audience: "https://pinnaclereliability.com",
            scope: "email profile"
        }
    },
    FileConfig:{
        restrictions:{
            maxTotalFileSize: "1073741824",
            maxNumberOfFiles: "30",
        },
        fileManagerSelectionType: "both"
    },
    walkMeSrc: "https://cdn.walkme.com/users/6ac8332db83a4ea4b2d93b7b4009ae06/walkme_6ac8332db83a4ea4b2d93b7b4009ae06_https.js",
    techSupport: {
        emailAddress: "support@pinnacletech.com"
    },
    projectQuestions: {
        toEmailAddress: "Platform@pinnaclereliability.com"
    },
    maxIdleTimeToLogout: {
        seconds: "43200"
    },
    signalR: {
        accessKey: "h6ABphifl6C/V5R7Mc5/S5k67J5Bj2UR884TnE/tYYo="
    },
    GoogleAnalytics: {
        measurementId: "G-89YYYX2N4R"
    },
    ZenDesk: {
        key: "10271455-5f8b-4aaa-8468-f96ae59dae41"
    },
    PinnacleUsersDomain : ["pinnacletech.com", "pinnaclereliability.com"],
    MUI: {
        licenseKey: "1b74c4c760060652cf0473609c64fe0eTz01MzUwMCxFPTE2OTg5MzExODA0MTksUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
    }
};
